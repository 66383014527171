"use client";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/src/components/ui/button";
import { SERIES_PATH } from "@/src/constants/route";
import { EditorSeries, Tag } from "@/src/constants/types/seriesTypes";
import { useFirestoreCollection } from "@/src/hooks/useFirestoreCollection";
import {
  deleteSeries,
  syncMobileSeriesToEditor,
} from "@/src/utils/firebase/desktopMobileSeriesOperations";
import editorDatabase, {
  storage,
} from "@/src/utils/firebase/firebaseClientConfig";
import { User } from "@/src/utils/firebase/userOperations";
import { zodResolver } from "@hookform/resolvers/zod";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import slugify from "slugify";
import { toast } from "sonner";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import SeriesCoverUpload from "./SeriesCoverUpload";
import SeriesUserAddModal from "./SeriesUserAddModal";
import { TagManager } from "./TagManager";

export enum SeriesPublishStep {
  UNPUBLISHED = "unpublished",
  COMING_SOON = "comingSoon",
  PUBLISHED = "published",
}

const seriesSchema = z.object({
  name: z.string().min(1, "Name is required"),
  tagline: z.string().min(1, "Tagline is required"),
  description: z.string().min(1, "Description is required"),
  spiciness: z.number().min(0).max(3),
  freeEpisodesCount: z.number().min(0),
  episodePrice: z.number().min(1, "Price must be 1 or greater"),
  ongoing: z.boolean().default(false),
  releaseDate: z.date(),
  coverFile: z.instanceof(File).optional(),
  bannerFile: z.instanceof(File).optional(),
  fullScreenCoverFile: z.instanceof(File).optional(),
  customMetaTag: z.string().optional(),
  creatorIds: z.array(z.string()),
  collaboratorIds: z.array(z.string()),
  releaseDay: z.string().optional(),
});

type SeriesFormValues = z.infer<typeof seriesSchema>;

const SeriesForm = ({
  series,
  mutate,
}: {
  series: EditorSeries;
  mutate: (series: Partial<EditorSeries>) => Promise<void>;
}) => {
  const [creatorModalOpen, setCreatorModalOpen] = useState(false);
  const [collaboratorModalOpen, setCollaboratorModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [creators, setCreators] = useState<User[]>([]);
  const [collaborators, setCollaborators] = useState<User[]>([]);
  const router = useRouter();
  const hasClickedSubmit = useRef(false);

  const {
    bannerURL,
    coverURL,
    fullScreenCoverURL,
    name,
    tags,
    description,
    tagline,
    collaboratorIds,
    creatorIds,
    userId,
    id,
    spiciness,
    updatedAt,
    publishState,
    freeEpisodesCount,
    episodePrice,
    ongoing,
    releaseDate,
    customMetaTag,
    releaseDay,
  } = series;

  const form = useForm<SeriesFormValues>({
    mode: "onSubmit",
    resolver: zodResolver(seriesSchema),
    defaultValues: {
      name: name,
      tagline: tagline,
      description: description,
      spiciness: spiciness || 0,
      freeEpisodesCount: freeEpisodesCount,
      episodePrice: episodePrice,
      ongoing: ongoing,
      releaseDate: releaseDate?.toDate() ?? new Date(),
      customMetaTag: customMetaTag || "",
      releaseDay: releaseDay,
    },
  });

  const {
    data: allTags,
    addDocument: addTag,
    updateDocument: updateTag,
    removeDocument: removeTag,
  } = useFirestoreCollection<Tag>("comicSeriesTags");

  useEffect(() => {
    const fetchUsers = async (ids: string[]) => {
      if (ids.length === 0) return [];
      const usersCollection = collection(editorDatabase, "users");
      const q = query(usersCollection, where("uid", "in", ids));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => doc.data() as User);
    };

    const fetchCreatorsAndCollaborators = async () => {
      const [fetchedCreators, fetchedCollaborators] = await Promise.all([
        fetchUsers(creatorIds),
        fetchUsers(collaboratorIds),
      ]);
      setCreators(fetchedCreators);
      setCollaborators(fetchedCollaborators);
      form.reset({
        creatorIds: fetchedCreators.map((creator) => creator.uid),
        collaboratorIds: fetchedCollaborators.map(
          (collaborator) => collaborator.uid
        ),
      });
    };

    if (creatorIds.length > 0 || collaboratorIds.length > 0) {
      fetchCreatorsAndCollaborators();
    }
  }, [creatorIds, collaboratorIds, form.reset, form]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const confirmMsg =
        "You have unsaved changes in the form. Are you sure you want to leave?";
      if (form.formState.isDirty && !hasClickedSubmit.current) {
        if (!window.confirm(confirmMsg)) {
          e.preventDefault();
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [form.formState.isDirty]);

  const onSubmit = async (
    values: SeriesFormValues,
    publishState: SeriesPublishStep,
    syncToMobile = true
  ) => {
    hasClickedSubmit.current = true;
    setIsUpdating(true);
    try {
      let updatedCoverURL = series.coverURL;
      let updatedBannerURL = series.bannerURL;
      let updatedFullScreenCoverURL = series.fullScreenCoverURL;

      if (values.coverFile) {
        updatedCoverURL =
          (await uploadImage(
            values.coverFile,
            "series_thumbnails",
            "coverURL"
          )) || series.coverURL;
      }
      if (values.bannerFile) {
        updatedBannerURL =
          (await uploadImage(
            values.bannerFile,
            "series_banners",
            "bannerURL"
          )) || series.bannerURL;
      }
      if (values.fullScreenCoverFile) {
        updatedFullScreenCoverURL =
          (await uploadImage(
            values.fullScreenCoverFile,
            "series_full_screen_covers",
            "fullScreenCoverURL"
          )) || series.fullScreenCoverURL;
      }
      const {
        coverFile,
        bannerFile,
        fullScreenCoverFile,
        ...valuesWithoutFiles
      } = values;

      const updatedSeries: EditorSeries = {
        ...series,
        ...valuesWithoutFiles,
        description: values.description.replace(/\n/g, "<br>"), // Replace newlines with <br> tags
        releaseDate: Timestamp.fromDate(values.releaseDate),
        coverURL: updatedCoverURL,
        bannerURL: updatedBannerURL,
        fullScreenCoverURL: updatedFullScreenCoverURL,
        updatedAt: Timestamp.now(),
        publishState: publishState,
      };

      await mutate(updatedSeries);
      if (syncToMobile) {
        await syncMobileSeriesToEditor(updatedSeries, publishState);
      }
      toast.success("Series updated successfully");
      form.reset(values); // Reset the form with the new values
      window.location.reload();
      hasClickedSubmit.current = false;
    } catch (error) {
      console.error(error);
      toast.error(`Failed to update series: ${error}`);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async () => {
    if (
      confirm(
        "Are you sure you want to delete this series? This action cannot be undone."
      )
    ) {
      await deleteSeries({ id: id });
      router.replace(SERIES_PATH);
    }
  };

  const handleUnpublish = async () => {
    if (confirm("Are you sure you want to unpublish this series?")) {
      const syncSuccess = await syncMobileSeriesToEditor(
        series,
        SeriesPublishStep.UNPUBLISHED
      );
      if (syncSuccess) {
        await mutate({ publishState: SeriesPublishStep.UNPUBLISHED });
        toast.success("Series unpublished successfully");
      }
    }
  };

  const uploadImage = async (
    file: File,
    folder: string,
    mutateKey: "coverURL" | "bannerURL" | "fullScreenCoverURL"
  ) => {
    if (!file.type.startsWith("image")) {
      alert("File is not an image!");
      return;
    }
    const fileName = slugify(file.name, { strict: true });
    const storageRef = ref(
      storage,
      `${folder}/${new Date().getTime()}-${userId}-${fileName}`
    );
    const snapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  };

  const handleTagAdd = (tagName: string) => {
    mutate({ tags: [...tags, tagName] });
  };

  const handleTagRemove = (tagName: string) => {
    mutate({ tags: tags.filter((t) => t !== tagName) });
  };

  const handleAddNewTag = async (tagName: string) => {
    if (tagName.trim() && !allTags?.some((t) => t.name === tagName.trim())) {
      await addTag({ name: tagName.trim(), id: tagName.trim() });
    }
  };

  return (
    <div className="flex flex-col w-full items-start text-center mt-[30px]">
      <SeriesUserAddModal
        currentUserIds={creatorIds}
        isOpen={creatorModalOpen}
        setModalOpen={setCreatorModalOpen}
        type="Creators"
        addUser={async (userId: string) => {
          await mutate({ creatorIds: [...creatorIds, userId] });
        }}
      />

      <SeriesUserAddModal
        currentUserIds={collaboratorIds as string[]}
        isOpen={collaboratorModalOpen}
        setModalOpen={setCollaboratorModalOpen}
        type="Collaborators"
        addUser={async (userId: string) => {
          await mutate({ collaboratorIds: [...collaboratorIds, userId] });
        }}
      />
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Button
                variant="ghost"
                className="text-lg text-gray-400"
                onClick={() => {
                  router.push(SERIES_PATH);
                }}
              >
                My Series
              </Button>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage className="text-lg">{name}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((values) =>
            onSubmit(values, publishState, true)
          )}
          className="space-y-4 w-full"
        >
          <div className="flex gap-4 w-full">
            <div className="text-left grow space-y-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input {...field} placeholder="Enter the series name" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tagline"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tagline</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Enter the series tagline"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        placeholder="Enter the series description"
                        defaultValue={field.value.replace(/<br>/g, "\n")} // Convert <br> tags back to newlines for editing
                        rows={5}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="customMetaTag"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Custom Meta Tag</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Enter custom meta tag (optional)"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="releaseDay"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Release Day</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select release day" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="Mon">Monday</SelectItem>
                          <SelectItem value="Tue">Tuesday</SelectItem>
                          <SelectItem value="Wed">Wednesday</SelectItem>
                          <SelectItem value="Thu">Thursday</SelectItem>
                          <SelectItem value="Fri">Friday</SelectItem>
                          <SelectItem value="Sat">Saturday</SelectItem>
                          <SelectItem value="Sun">Sunday</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Tags Section */}
              <TagManager
                currentTags={tags}
                allTags={allTags}
                onAddTag={handleTagAdd}
                onRemoveTag={handleTagRemove}
                onAddNewTag={handleAddNewTag}
                onUpdateTag={updateTag}
                onDeleteTag={removeTag}
              />

              {/* Creators Section */}
              <div>
                <div className="text-sm text-[#8b898c] font-medium mt-[24px]">
                  Creators
                </div>
                <div className="flex gap-2 flex-wrap mt-[12px]">
                  {creators.map((creator) => (
                    <Button
                      key={creator.uid}
                      type="button"
                      variant="outline"
                      className="flex items-center p-[12px] text-sm whitespace-nowrap hover:bg-[#3d3d3d] cursor-pointer"
                      onClick={() => {
                        mutate({
                          creatorIds: creatorIds.filter(
                            (id) => id !== creator.uid
                          ),
                        });
                      }}
                    >
                      {creator.email}
                      <RemoveCircleOutlineRoundedIcon className="ml-1 text-red-300" />
                    </Button>
                  ))}
                </div>
                <Button
                  type="button"
                  variant="outline"
                  className="mt-[12px]"
                  onClick={() => setCreatorModalOpen(true)}
                >
                  Add Creator
                </Button>
              </div>

              {/* Collaborators Section */}
              <div>
                <div className="text-sm text-[#8b898c] font-medium mt-[24px]">
                  Collaborators
                </div>
                <div className="flex gap-2 flex-wrap mt-[12px]">
                  {collaborators.map((collaborator) => (
                    <Button
                      type="button"
                      key={collaborator.uid}
                      variant="outline"
                      className="flex items-center p-[12px] text-sm whitespace-nowrap hover:bg-[#3d3d3d] cursor-pointer"
                      onClick={() => {
                        mutate({
                          collaboratorIds: collaboratorIds.filter(
                            (id) => id !== collaborator.uid
                          ),
                        });
                      }}
                    >
                      {collaborator.email}
                      <RemoveCircleOutlineRoundedIcon className="ml-1 text-red-300" />
                    </Button>
                  ))}
                </div>
                <Button
                  type="button"
                  variant="outline"
                  className="mt-[12px]"
                  onClick={() => setCollaboratorModalOpen(true)}
                >
                  Add Collaborator
                </Button>
              </div>

              <div className="flex flex-row gap-4">
                <FormField
                  control={form.control}
                  name="freeEpisodesCount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Free Episodes</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          {...field}
                          defaultValue={field.value}
                          onChange={(e) =>
                            field.onChange(Number(e.target.value))
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="episodePrice"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Episode Price</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          {...field}
                          defaultValue={field.value}
                          onChange={(e) =>
                            field.onChange(Number(e.target.value))
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="spiciness"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Spiciness Level</FormLabel>
                    <Select
                      onValueChange={(value) => field.onChange(Number(value))}
                      value={field.value.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select spiciness level" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="0">None 🍦</SelectItem>
                        <SelectItem value="1">Mild 🌶️</SelectItem>
                        <SelectItem value="2">Bold 🌶️🌶️</SelectItem>
                        <SelectItem value="3">Fiery 🌶️🌶️🌶</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="ongoing"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>Ongoing</FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="releaseDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Release Date</FormLabel>
                    <FormControl>
                      <Input
                        type="date"
                        {...field}
                        value={field.value.toISOString().split("T")[0]}
                        onChange={(e) =>
                          field.onChange(new Date(e.target.value))
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="space-y-2 mt-4">
                <div className="text-sm text-[#8b898c] font-medium">
                  Current status: {publishState}
                </div>
                <div className="flex flex-row gap-4">
                  <Button
                    variant="secondary"
                    type="submit"
                    className="w-full"
                    onClick={form.handleSubmit((values) =>
                      onSubmit(values, SeriesPublishStep.UNPUBLISHED, true)
                    )}
                    disabled={isUpdating}
                  >
                    {publishState === SeriesPublishStep.UNPUBLISHED
                      ? "(1) Update"
                      : "(1) Unpublish"}
                  </Button>

                  <Button
                    variant="secondary"
                    type="submit"
                    className="w-full"
                    onClick={form.handleSubmit((values) =>
                      onSubmit(values, SeriesPublishStep.COMING_SOON, true)
                    )}
                    disabled={isUpdating}
                  >
                    {publishState === SeriesPublishStep.COMING_SOON
                      ? "(2) Update"
                      : "(2) Set to Coming Soon"}
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-full"
                    type="submit"
                    onClick={form.handleSubmit((values) =>
                      onSubmit(values, SeriesPublishStep.PUBLISHED, true)
                    )}
                    disabled={isUpdating}
                  >
                    {publishState === SeriesPublishStep.PUBLISHED
                      ? "(3) Update"
                      : "(3) Publish"}
                  </Button>
                </div>
                <Button
                  variant="destructive"
                  className="w-full"
                  onClick={handleDelete}
                  disabled={isUpdating}
                >
                  Delete Series
                </Button>
              </div>
            </div>

            <div className="text-left shrink-0 space-y-4">
              <SeriesCoverUpload
                control={form.control}
                name="coverFile"
                label="Cover"
                aspectRatio="3:4"
                height={400}
                width={300}
                currentUrl={coverURL}
                placeholderSrc="/images/placeholderHumbnail.svg"
              />

              <SeriesCoverUpload
                control={form.control}
                name="bannerFile"
                label="Banner"
                aspectRatio="39:35"
                height={269}
                width={300}
                currentUrl={bannerURL}
                placeholderSrc="/images/placeholderHumbnail.svg"
              />
            </div>

            <div className="text-left shrink-0">
              <SeriesCoverUpload
                control={form.control}
                name="fullScreenCoverFile"
                label="Full Screen Cover"
                aspectRatio="9:19"
                height={633}
                width={300}
                currentUrl={fullScreenCoverURL}
                placeholderSrc="/images/placeholderHumbnail.svg"
              />
            </div>
          </div>
        </form>
      </Form>

      {updatedAt && (
        <div className="text-[12px] text-[#8b898c] font-medium mt-4">
          Last updated: {updatedAt.toDate().toLocaleString()}
        </div>
      )}
    </div>
  );
};

export default SeriesForm;
